import useSWRImmutable from 'swr/immutable'
import { getHttpClient } from '@apiClient'
import { throttle, isNull, isString } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'
import { useBoolean } from 'react-use'
import { mutate as swrMutate } from 'swr'

export default function useFetch(
  // 请求参数相关
  fetchCfg = {},
  // swr config
  swrCfg
) {

  const { method = 'get', url, params, delay = false, fetchKey, cfCache = true } = useMemo(() => {
    return isString(fetchCfg) || isNull(fetchCfg) ? { url: fetchCfg } : fetchCfg
  }, [fetchCfg])
  // swr key
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callGetKey = useCallback(getKeyByDelay(delay), [delay])
  const getKey = useCallback((url, params) => callGetKey(url, params), [callGetKey])
  const key = useMemo(() => fetchKey ? fetchKey : getKey(url, params), [fetchKey, getKey, url, params])
  const abortController = useMemo(() => new AbortController(key), [key])
  useEffect(() => () => abortController.abort(), [abortController])

  const fetcher = getFetcher(method, url, params, abortController, cfCache)
  const { data, error, isLoading, mutate } = useSWRImmutable(key, fetcher, swrCfg)

  function cancel() {
    abortController.abort()
  }

  // reload loading state
  const [reloadLoading, setReloadLoading] = useBoolean(false)
  async function reload() {
    setReloadLoading(true)
    const data = await fetcher().finally(() => setReloadLoading(false))
    mutate(data)
  }

  const clear = useCallback(() => {
    mutate(undefined, false)
    swrMutate(key, undefined, false)
  }, [key, mutate])

  return {
    data,
    error,
    isLoading: isLoading || reloadLoading,
    cancel,
    reload,
    clear
  }

}

function getKeyByDelay(delay = 1000) {
  function _get(url, params) {
    if (params) {
      const kyes = Object.keys(params)
      if (kyes.length === 0) return url
      // 按key排升序
      kyes.sort()
      // 按key生成字符串
      const str = kyes.map(key => `${key}=${params[key]}`).join('&')
      // 生成hash
      const hash = str ? `?${str}` : ''
      // 生成key
      return `${url}${hash}`
    } else {
      return url
    }
  }
  return delay ? throttle(_get, delay, { leading: true, trailing: true }) : _get
}

function getFetcher(method, url, params, abortController, cfCache) {
  const http = getHttpClient()
  const signal = abortController.signal
  http.cfCache = cfCache
  return () => http[method](url, params, { signal })
}